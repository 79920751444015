import React, { useRef, useState, useEffect } from "react";
import ReactDOM from "react-dom";
import { FirebaseService } from "../../services/FirebaseService";
import showToast from "../../common/toastify";
import { StartSpinner, StopSpinner } from "../../common/Spinner/spinner";
import { getDateAsInputString } from "../../common/UtilitiyFunctions";
import { CustomSwal } from "../../common/SwalMixin";
import moment from "moment";
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import ProfileGoogleAddressPickerComponent from '../../common/GoogleMap/ProfileGoogleAddressPickerComponent';
import VerifyEmailComponent from "../../users/components/VerifyEmailComponent";
const currentDate = new Date()
currentDate.setFullYear(currentDate.getFullYear() - 18);
const EmailPasswordLoginComponent = ({ email, mode, formFields: fFields, setFormFields: setFFields,oldAddressValue,setOldAddressValue, ...props }) => {
  const emailRef = useRef(null);
  const [formFields, setFormFields] = useState({
    email: email || "",
    phone: "",
    password: "",
    re_enter_password: "",
    first_name: fFields?.first_name || "",
    last_name: fFields?.last_name || "",
    dob: "",
    referral:fFields?.referral || "",
    marketing_email_subscription:fFields?.marketing_email_subscription ?? false,
    remember: false,
    address: fFields.address || "",
    city: fFields.city || "",
    state:fFields.state || "",
    country: fFields.country || "",
    zip: fFields.zip || "",
    lat: fFields.lat || "",
    long: fFields.long || "",
  });
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [passwordToggles, setPasswordToggles] = useState({
    pass1: false,
    pass2: false,
  });

  const [disabled, setDisabled] = useState(false);
  const [validitiyFactors, setValiditiyFactors] = useState({
    hasLength: false,
    hasUpperCase: false,
    hasLowerCase: false,
    hasNumber: false,
    matches: true,
    hasSpecialCharacter: false,
    error_password: true,
    error_re_enter_password: true,
  });

  useEffect(() => {
    const error_password = /^\S*$/.test(formFields.password);
    const error_re_enter_password = /^\S*$/.test(formFields.re_enter_password);
    const password = formFields.password;
    const re_enter_password = formFields.re_enter_password;
    const hasLength = password.length >= 6;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialCharacter = /[!@#\$%\^\&*\)\(+=._-]/.test(password);
    const matches = true || password === re_enter_password;

    setValiditiyFactors({
      hasLength,
      hasUpperCase,
      hasLowerCase,
      hasNumber,
      matches,
      hasSpecialCharacter,
      error_password,
      error_re_enter_password,
    });
  }, [formFields]);

  const checkPasswordMatch = () => {
    const error_re_enter_password = /^\S*$/.test(formFields.re_enter_password);
    const password = formFields.password;
    const re_enter_password = formFields.re_enter_password;
    const matches = password === re_enter_password;
    setValiditiyFactors({
      ...validitiyFactors,
      error_re_enter_password,
      matches
    });
  };

  const checkValidation = () => {
    const isFirstName = /^(?!\s*$).+/.test(formFields.first_name || "");
    const isLastName = /^(?!\s*$).+/.test(formFields.last_name || "");
    const isEmail = /^(?!\s*$).+/.test(formFields.email || "");    
    const isDOB = /^(?!\s*$).+/.test(formFields.dob || "");
    const isReferral = /^(?!\s*$).+/.test(formFields.referral || "");
    const isPassword = /^(?!\s*$).+/.test(formFields.password || "");
    const isReenterPassword = /^(?!\s*$).+/.test(formFields.re_enter_password || "");  
    const isInvalidEmail = /^[\w+.-]+(\.[\w+.-]+)*@\w+([\.-]?\w+)*(\.\w{2,})+$/.test(formFields.email || "");    
    const isValidPhone = formFields.phone ? /^[0-9]{10,10}$/.test(formFields.phone) : true;

    let isValid = true,passwordNotMatched = true;

    if(!isFirstName) showToast("Please enter your legal first name", "error");
    else if(!isLastName) showToast("Please enter your legal last name", "error");
    else if(!isEmail) showToast("Please enter your email", "error");
    else if(!isInvalidEmail) showToast("Please enter correct email", "error");
    else if(formFields.phone && !isValidPhone) showToast("Please enter correct phone number", "error");
    else if(!isDOB) showToast("Please select your date of birth", "error");
    else if(!isReferral) showToast("Please let us know how did you hear about Cheezoo");
    else {
      if(isPassword){
        for (const key of Object.keys({
          hasLength: false,
          hasUpperCase: false,
          hasLowerCase: false,
          hasNumber: false,
          hasSpecialCharacter: false,
        })) {
          if (!validitiyFactors[key]) {
            isValid = false;
          }
        }
        if (!isValid) {
          showToast("Please enter your valid password", "error");
        } else if (isReenterPassword && formFields.password !== formFields.re_enter_password) {
          showToast("Passwords do not match", "error"); 
          passwordNotMatched = false;
        }
      }
      if(!isPassword) showToast("Please enter your password", "error");
      else if(!isReenterPassword && isValid) showToast("Please re-enter your password", "error");
    }

    if(!isFirstName || !isLastName || !isEmail || !isInvalidEmail || !isDOB || !isReferral || !isPassword || !isReenterPassword || !isValid || !passwordNotMatched || (formFields.phone && !isValidPhone)) return false;
    return true;
  }

  const handleLogin = async (e) => {
    try {
      e.preventDefault();
      if (mode == "register" && !checkValidation()) return;
      setDisabled(true);
      StartSpinner();

      /* if (formFields.remember) {
        var creds = Buffer.from(
          `${formFields.email}:${formFields.password}`
        ).toString("base64");
        localStorage.setItem("creds", creds);
      } */

      // check if user exists in case of login
      if (mode == "login") {
        const resp = await fetch(
          "/user-exists?email=" + encodeURIComponent(formFields.email)
        );
        const data = await resp.json();
        if (!data.exists) {
          throw new Error("auth/user-not-found");
        }
        if(!formFields.password){
          throw new Error("password_required");
        }
        const { firebaseUser, credential } =
          await FirebaseService.loginUsingEmailPassword(
            formFields.email,
            formFields.password
          );
        StopSpinner();
        if (props.onUserSignIn) {
          props.onUserSignIn(firebaseUser, credential, "emailpassword");
        }
        if(!firebaseUser.emailVerified){
          setShowVerificationModal(true);
        }
      } else if (mode == "register") {
        if(sessionStorage.getItem('bookingFlowData'))
        localStorage.setItem("bookingFlowData", sessionStorage.getItem('bookingFlowData'));

        if(!formFields.address){
          if(window.location.href.includes('/register-client')){
            throw new Error("zipcode_required");
          } else{
            throw new Error("address_required");
          }
        }
        
        if(!formFields.dob){
          throw new Error("dob_required");
        }

        const resp = await fetch(
          "/user-exists?email=" + encodeURIComponent(formFields.email)
        );
        const data = await resp.json();
        if (data.exists) {
          throw new Error("auth/email-already-in-use");
        }
        const { firebaseUser, credential, registered, code=null } = await FirebaseService.registerUsingEmailPssword(
            formFields.email,
            formFields.password,
            formFields.first_name + ' ' + formFields.last_name,
            formFields.phone // Pass phone number to registration
          );
        if(registered === false){
          throw {code: code || "auth/email-already-in-use",message:''};
        }
        setShowVerificationModal(true);
        StopSpinner();
        const { sent } = await FirebaseService.verifyEmail();
        if (props.onUserSignIn) {
          props.onUserSignIn(
            firebaseUser,
            credential,
            "emailpassword",
            formFields
          );
        }
      }
      StopSpinner();
    } catch (error) {
      StopSpinner();
      if (error.code === "auth/wrong-password") {
        showToast("Incorrect password", "error");
      } else if (
        error.code === "auth/user-not-found" ||
        error.message === "auth/user-not-found"
      ) {
        showToast("User not found. Please create your account first", "error");
      } else if (error.message == "invalid_password") {
        showToast("Please enter your password", "error");
      } else if (error.message == "auth/email-already-in-use" || error.message == "invalid_password") {
        showToast(
          "The account already exists for that email. Please login",
          "error",false
        );
      } else if (error?.code == "auth/email-already-in-use") {
        showToast(
          "The account already exists for that email. Please login",
          "error",false
        );
      } else if (error?.code === "auth/invalid-email") {
        showToast("Please enter correct email", "error");
      } else if(error?.message == 'zipcode_required'){
        showToast("Please enter your valid zip code", "error");
      } else if(error?.message == 'address_required'){
        showToast("Please enter your valid address", "error");
      } else if(error?.message == 'dob_required'){
        showToast("Please select your date of birth", "error"); 
      } else if(error?.message == 'password_required'){
        showToast("Please enter your password", "error"); 
      } else if(error?.code == 'auth/too-many-requests'){
        showToast("Too many requests", "error"); 
      } else {
        showToast(error, "error");
      }
      setDisabled(false);
    }
  };

  useEffect(() => {
    if (emailRef.current && mode == 'login') {
      emailRef.current.focus();
    }
  }, [emailRef]);

  useEffect(() => {
    if (props.onInputChange) {
      props.onInputChange(formFields.email);
    }
  }, [formFields.email]);

  
  useEffect(() => {
    if(fFields && setFFields)
    setFFields({...fFields,...formFields})
  }, [formFields])
  

  // useEffect(() => {
  //   if(mode == 'register'){
  //   setTimeout(() => {
  //     if(!FirebaseService?.auth) return;
  //     console.log('Firebase Data',FirebaseService?.auth?.currentUser);
  //     if(FirebaseService?.auth && FirebaseService?.auth?.currentUser?.email && !FirebaseService?.auth?.currentUser?.emailVerified){
  //       setShowVerificationModal(true); 
  //     }
  //   }, 1000);
  // }
  // }, []);



  useEffect(() => {
    if(showVerificationModal){
    const modalComponent = document.createElement("div");
    ReactDOM.render(<VerifyEmailComponent mode={mode} email={formFields.email || FirebaseService.auth?.currentUser?.email} />, modalComponent )  
    CustomSwal.fire({
      title: "Registered!",
      icon: "success",
      allowOutsideClick: false,
      allowEscapeKey: false,
      html: modalComponent,
      showConfirmButton: false,
     })
  }
  }, [showVerificationModal])
  
  return (
    <>
    <form onSubmit={(e) => handleLogin(e)} autoComplete="off">
      {mode == "register" && (
        <>
          <div className="form-floating mb-4">
            <input
              type="text"
              className="form-control"
              id="FirstName"
              placeholder="Legal First Name"
              value={formFields.first_name}
              onChange={(e) =>
                setFormFields({ ...formFields, first_name: e.target.value })
              }
              onBlur={(e) => {
                setFormFields({ ...formFields, first_name: formFields.first_name.trim() })
              }}
            />
            <label htmlFor="FirstName">Legal First Name</label>
          </div>
          <div className="form-floating mb-4">
            <input
              type="text"
              className="form-control"
              id="LastName"
              placeholder="Legal Last Name"
              value={formFields.last_name}
              onChange={(e) =>
                setFormFields({ ...formFields, last_name: (e.target.value) })
              }
              onBlur={(e) => {
                setFormFields({ ...formFields, last_name: formFields.last_name.trim() })
              }}
            />
            <label htmlFor="LastName">Legal Last Name</label>
          </div></>
      )}
      <div className="form-floating mb-4">
        <input
          type="email"
          className="form-control"
          id="Email"
          placeholder="Email"
          ref={emailRef}
          value={formFields.email}
          onChange={(e) => {
            setFormFields({ ...formFields, email: e.target.value })
          }}
          onBlur={(e) => {
            setFormFields({ ...formFields, email: formFields.email.trim() })
          }}
          
        />
        <label htmlFor="Email">Email</label>
      </div>
      {mode == "register" && (
        <div className="form-floating mb-4">
          <input
            type="tel"
          className="form-control"
          id="Phone"
          placeholder="Phone Number (Optional)"
          value={formFields.phone}
          onChange={(e) => {
            const value = e.target.value.replace(/\D/g, '');
            if (value.length <= 10) {
              setFormFields({ ...formFields, phone: value })
            }
          }}
          onBlur={(e) => {
            setFormFields({ ...formFields, phone: formFields.phone.trim() })
          }}
          />
          <label htmlFor="Phone">Phone Number (Optional)</label>
        </div>
      )}
      {mode == "register" && (
        <>
          <div className="form-floating date-input-format mb-4">
            {/* <input
              type="date"
              className="form-control"
              id="dob"
              placeholder="Date of Birth"
              max={getDateAsInputString(currentDate)}
              required={true}
              value={formFields.dob}
              onChange={(e) =>
                setFormFields({ ...formFields, dob: e.target.value })
              }
            /> */}
            {/* <DatePicker
              value={formFields.dob}
              initialMonth={formFields.dob ? moment(formFields.dob).toDate() : moment(currentDate).toDate()}
              maxDate={moment(currentDate).toDate()}
              onChange={(e) => {
                if (!e) return;
                setFormFields({ ...formFields, dob: moment(e).format('YYYY-MM-DD') })
              }
              }
              required={true}
              placeholder="Date of birth"
              maw={400}
              mx="auto"
            /> */}
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <MobileDatePicker
                format="LL"
                closeOnSelect={true}
                disableCloseOnSelect={false}
                value={formFields.dob ? moment(formFields.dob) : null}
                maxDate={moment(currentDate)}
                slotProps={{
                  textField: {
                    placeholder: "Date of Birth"
                  },
                  actionBar: {
                    actions: []
                  }
                }}
                onChange={(e) => {
                  if (!e) return;
                  setFormFields({ ...formFields, dob: moment(e).format('YYYY-MM-DD') })
                }
                }
               />
            </LocalizationProvider>
            {/* <label htmlFor="dob">Date of birth</label> */}
          </div>
          <div className={'mb-4'}>
            {/* <label htmlFor="referral" className="p_user_name font16">How did you hear about Cheezoo?</label> */}
            <select
              className={
                "form-select"}
              id="referral"
              name="referral_email"
              value={formFields.referral}
              onChange={(e) => {
                setFormFields({ ...formFields, referral: e.target.value });
              }}
              style={{color: formFields?.referral == "" ? "var(--inputfilttext)" : "var(--gray)"}}  
            >
              <option value="" disabled style={{color: "var(--inputfilttext)"}}>How did you hear about Cheezoo?</option>
              <option value="Google" style={{color: "var(--gray)"}} >Google</option>
              <option value="Instagram" style={{color: "var(--gray)"}} >Instagram</option>
              <option value="Facebook" style={{color: "var(--gray)"}} >Facebook</option>
              <option value="TikTok" style={{color: "var(--gray)"}} >TikTok</option>
              <option value="YouTube" style={{color: "var(--gray)"}} >YouTube</option>
              <option value="LinkedIn" style={{color: "var(--gray)"}} >LinkedIn</option>
              <option value="Friend/Family" style={{color: "var(--gray)"}} >Friend/Family</option>
              <option value="Other" style={{color: "var(--gray)"}} >Other</option>
            </select>
          </div>
        </>
      )}
      {formFields.email &&
        <div className="form-floating input-group mb-4">
          <input
            type={passwordToggles.pass1 ? "text" : "password"}
            className="form-control"
            id="password"
            placeholder="Password"
            value={formFields.password}
            onBlur={checkPasswordMatch}
            onChange={(e) =>
              setFormFields({ ...formFields, password: e.target.value })
            }
          />
          <label htmlFor="password">Password</label>
          <button
            type="button"
            className="btn btn-group pt-3 remove-cros-btn"
            onClick={() =>
              setPasswordToggles({
                ...passwordToggles,
                pass1: !passwordToggles.pass1,
              })
            }
          >
            {passwordToggles.pass1 ? <i className="fa-solid fa-eye grey"></i> : <i className="fa fa-eye-slash grey" aria-hidden="true"></i>}
          </button>
        </div>}
      {formFields.password && !validitiyFactors.error_password && (
        <div className="error-msg"> Do not use space in password </div>
      )}
      {mode == "register" && formFields.password && (
        <div className="mb-4 text-start">
          <ul className="bullet-size">
            <li className={validitiyFactors.hasLength ? "check-password-condition" : (validitiyFactors.hasLength) ? "password-not-match" : "mr-2"}>
              At least 6 characters{" "}
            </li>
            <li className={validitiyFactors.hasUpperCase ? "check-password-condition" : (validitiyFactors.hasUpperCase) ? "password-not-match" : "mr-2"}>
              At least 1 upper case letter (A-Z){" "}
            </li>
            <li className={validitiyFactors.hasLowerCase ? "check-password-condition" : (validitiyFactors.hasLowerCase) ? "password-not-match" : "mr-2"}>
              At least 1 lower case letter (a-z){" "}
            </li>
            <li className={validitiyFactors.hasNumber ? "check-password-condition" : (validitiyFactors.hasNumber) ? "password-not-match" : "mr-2"}>
              At least 1 number (0-9){" "}
            </li>
            <li className={validitiyFactors.hasSpecialCharacter ? "check-password-condition" : (validitiyFactors.hasSpecialCharacter) ? "password-not-match" : "mr-2"}>
              At least 1 symbol ( ! @ # $ & * ~ ){" "}
            </li>
          </ul>
        </div>
      )}
      {mode == "register" && formFields.email && (
        <>
          <div className={formFields.password &&
            formFields.re_enter_password &&
            !validitiyFactors.matches ? "form-floating input-group" : "form-floating input-group mb-4"}>
            <input
              type={passwordToggles.pass2 ? "text" : "password"}
              className="form-control"
              id="Re-enterPassword"
              placeholder="Re-enter Password"
              value={formFields.re_enter_password}
              onBlur={checkPasswordMatch}
              onChange={(e) =>
                setFormFields({
                  ...formFields,
                  re_enter_password: e.target.value,
                })
              }
            />
            <label htmlFor="Re-enterPassword">Re-enter password</label>
            <button
              type="button"
              className="btn btn-group pt-3 remove-cros-btn"
              onClick={() =>
                setPasswordToggles({
                  ...passwordToggles,
                  pass2: !passwordToggles.pass2,
                })
              }
            >
              {passwordToggles.pass2 ? <i className="fa-solid fa-eye grey"></i> : <i className="fa fa-eye-slash grey" aria-hidden="true"></i>}
            </button>
          </div>
          {formFields.re_enter_password && !validitiyFactors.error_re_enter_password && (
            <div className="error-msg"> Do not use space in password </div>
          )}
          {formFields.password &&
            formFields.re_enter_password &&
            !validitiyFactors.matches && (
              <p className="required_feild text-start">Passwords do not match</p>
            )}
        </>
      )}
      {mode == "register" &&<><ProfileGoogleAddressPickerComponent
              types= {location.href.includes('register-client') ? {types: ['postal_code']} : null}
              inputLabel = {<></>}
              useDefaultCurrentLocation={false}
              showInfoModal={true}
              showMap={false}
              userAddress={formFields}
              className="d-flex align-items-center input-group mb-2" 
              locationError={true}
              locationErrorMessage={null}
              oldAddressValue={oldAddressValue}
              setOldAddressValue={setOldAddressValue}
              onChange={(addrComponent) => {
                setFormFields({
                  ...formFields,
                  address:
                    addrComponent?.addrLine1 || addrComponent?.address || "",
                  city: addrComponent.city,
                  state: addrComponent.state,
                  country: addrComponent.country,
                  zip: addrComponent.zip,
                  lat: addrComponent.lat,
                  long: addrComponent.lng,
                });
              }}
            /></> }
            {mode == "register" && <div
      style={{
        position:"relative",
        alignItems:"center",
        marginBottom:"10px"
      }}
       htmlFor="check" className="d-flex">
        <input type="checkbox" checked={formFields.marketing_email_subscription}
        onChange={e => setFormFields({
          ...formFields,
          marketing_email_subscription: e.target.checked,
        })}
        id="check" className="g_checkbox form-check-input" style={{
          position: "relative",
          left:"0px",
          top:"0px",
          marginRight:"10px"
        }}/>Subscribe to emails for tips, tricks, and recommendations</div>
      }
      {(mode == "login" && !window.location.pathname.includes('/admin-login')) && (
        <div>
          <a
            className="d-block mb-4 forgot-password-text underline-grey"
            href={`/forgot-password?email=${encodeURIComponent(formFields.email)}`}
          >
            Forgot Password?
          </a>
        </div>
      )}
      <button type="submit" disabled={disabled} className="btn-w-full btn-gray mb-4">
        {mode == "register" ? "Sign Up" : "Sign In"}{" "}
        {mode != "register" &&
          <svg
            width="21"
            height="20"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M5.66039 15.1848C5.26987 14.7943 4.6367 14.7943 4.24618 15.1848C3.85566 15.5753 3.85566 16.2085 4.24618 16.599L5.66039 15.1848ZM4.24618 3.39969C3.85566 3.79021 3.85566 4.42338 4.24618 4.8139C4.6367 5.20442 5.26987 5.20442 5.66039 4.8139L4.24618 3.39969ZM10.4147 6.83519C10.0478 6.42241 9.41574 6.38522 9.00295 6.75214C8.59017 7.11906 8.55299 7.75113 8.91991 8.16392L10.4147 6.83519ZM11.8895 9.99955L12.6369 10.6639C12.9737 10.285 12.9737 9.71407 12.6369 9.33519L11.8895 9.99955ZM8.91991 11.8352C8.55299 12.248 8.59017 12.88 9.00295 13.247C9.41574 13.6139 10.0478 13.5767 10.4147 13.1639L8.91991 11.8352ZM11.7507 10.9996C12.3029 10.9996 12.7507 10.5518 12.7507 9.99955C12.7507 9.44727 12.3029 8.99955 11.7507 8.99955V10.9996ZM1.33398 8.99955C0.7817 8.99955 0.333984 9.44727 0.333984 9.99955C0.333984 10.5518 0.7817 10.9996 1.33398 10.9996V8.99955ZM16.0313 4.8139C18.8951 7.67774 18.8951 12.321 16.0313 15.1848L17.4455 16.599C21.0904 12.9541 21.0904 7.04458 17.4455 3.39969L16.0313 4.8139ZM16.0313 15.1848C13.1674 18.0486 8.52424 18.0486 5.66039 15.1848L4.24618 16.599C7.89107 20.2439 13.8006 20.2439 17.4455 16.599L16.0313 15.1848ZM5.66039 4.8139C8.52424 1.95005 13.1674 1.95005 16.0313 4.8139L17.4455 3.39969C13.8006 -0.245207 7.89107 -0.245208 4.24618 3.39969L5.66039 4.8139ZM8.91991 8.16392L11.1421 10.6639L12.6369 9.33519L10.4147 6.83519L8.91991 8.16392ZM11.1421 9.33519L8.91991 11.8352L10.4147 13.1639L12.6369 10.6639L11.1421 9.33519ZM11.7507 8.99955H1.33398V10.9996H11.7507V8.99955Z"
              fill="white"
            />

          </svg>
        }
      </button>
    </form>
    </>
  );
};

export default EmailPasswordLoginComponent;
